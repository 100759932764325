export const KEY_ACCESS_TOKEN = 'local_access_token';
export const KEY_LAST_CURRENCY = 'local_last_currency';

export const setAccessToken = (token: string) =>
  setLocalValue(KEY_ACCESS_TOKEN, token);
export const getAccessToken = () => getLocalValue(KEY_ACCESS_TOKEN);
export const removeAccessToken = () => removeLocalValue(KEY_ACCESS_TOKEN);

export const setLastCurrency = (currency: string) =>
  setLocalValue(KEY_LAST_CURRENCY, currency);
export const getLastCurrency = () => getLocalValue(KEY_LAST_CURRENCY);

function removeLocalValue(key: string) {
  localStorage.removeItem(key);
}

function setLocalValue(key: string, value: string) {
  localStorage.setItem(key, value);
}

function getLocalValue(key: string) {
  return localStorage.getItem(key);
}
