export const config = {
  apiServer: {
    url:
      process.env.REACT_APP_PHASE === 'dev'
        ? 'http://localhost:5500'
        : 'https://api.pluswhere.com'
  },
  // chatServer: {
  //   url:
  //     process.env.REACT_APP_PHASE === 'dev'
  //       ? 'http://localhost:6500'
  //       : 'https://api-chat.pluswhere.com'
  // },
  currencyData: {
    url:
      process.env.REACT_APP_PHASE === 'dev'
        ? '/dummy/currency/latest.json'
        : 'https://pluswhere.com/currency/latest.json'
  },
  defaultImage: {
    countryImageUrlPrefix: 'https://pluswhere.com/images/countries'
  },
  noImage: {
    url: 'https://pluswhere.com/images/no_image.svg'
  },
  regex: {
    email: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
    password: /^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[~!@#$%^&*()_+<>?]).{8,}$/,
    code: /^([0-9]).{5}$/ //왜 5가 여섯자리인가...
  },
  avatar: {
    noneAvatarUrl: 'https://pluswhere.com/images/avatars/avatar-none.png'
  },
  keys: {
    google: {
      maps_key: 'AIzaSyB4ApL3ZwgBjhBHvRViV5B8H06S_1XT0kI'
    }
  },
  devMode: true,
  googleMapId: '4cc051fcc0132c5f'
};
