import { useApi } from './api';
import { ServerResponse } from './response/ServerResponse';
import { useMutation } from 'react-query';

export interface SignIn {
  email: string;
  password: string;
}

export const authApi = {
  signin: (email: string, password: string) =>
    useApi().post<ServerResponse<any>>('/auth/signin', {
      email: email,
      password: password
    }),
  refresh: () => useApi().post<ServerResponse<any>>('/auth/refresh', {}),
  sendRequestCode: (email: string) =>
    useApi().post<ServerResponse<any>>('/auth/request/code', {
      email: email
    }),
  sendConfirmCode: (email: string, code: string) =>
    useApi().post<ServerResponse<any>>('/auth/confirm/code', {
      email: email,
      code: code
    }),
  resetPassword: (email: string, code: string, password) =>
    useApi().post<ServerResponse<any>>('/auth/reset/password', {
      email: email,
      code: code,
      password: password
    })
};

export const useSigninMutation = () => {
  return useMutation(async (params: SignIn) => {
    try {
      const result = await authApi.signin(params.email, params.password);
      return result.data;
    } catch (err: any) {
      return {
        data: err.response.data,
        status: err.request.status
      };
    }
  });
};

export const useRefreshMutation = () => {
  return useMutation(async () => {
    try {
      const result = await authApi.refresh();
      return result.data;
    } catch (err: any) {
      return {
        data: err.response.data,
        status: err.request.status
      };
    }
  });
};

export const useRequestCodeMutation = () => {
  return useMutation(async (email: string) => {
    try {
      const result = await authApi.sendRequestCode(email);
      return result.data;
    } catch (err: any) {
      return {
        data: err.response.data,
        status: err.request.status
      };
    }
  });
};

export const useConfirmCodeMutation = () => {
  return useMutation(async (params: any) => {
    try {
      const result = await authApi.sendConfirmCode(params.email, params.code);
      return result.data;
    } catch (err: any) {
      return {
        data: err.response.data,
        status: err.request.status
      };
    }
  });
};

export const useResetPasswordMutation = () => {
  return useMutation(async (params: any) => {
    try {
      const result = await authApi.resetPassword(
        params.email,
        params.code,
        params.password
      );
      return result.data;
    } catch (err: any) {
      return {
        data: err.response.data,
        status: err.request.status
      };
    }
  });
};
