import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { User } from './user';

const { persistAtom } = recoilPersist();

export interface LoginInfo {
  isSignedIn: boolean;
  userInfo: User | null;
}

export interface LastLocale {
  language_code: string;
}

const initLoginState: LoginInfo = {
  isSignedIn: false,
  userInfo: null
};
// 로그인 상태 저장
export const LoginInfoState = atom<LoginInfo>({
  key: 'LoginState',
  default: initLoginState,
  effects_UNSTABLE: [persistAtom]
});

// 마지막에 설정한 Locale 정보저장 - 로그인 없을 시 저장 용도 : 따라서 로케일을 변경하면 여기에 저장한다.
export const LastLocaleState = atom<LastLocale>({
  key: 'LastLocaleState',
  default: null,
  effects_UNSTABLE: [persistAtom]
});
/** 현재 초대 count */
export const InvitationState = atom<number>({
  key: 'InvitationState',
  default: 0,
  effects_UNSTABLE: [persistAtom]
});
/** 접은 board codes */
export const FoldedBoardsState = atom<string[]>({
  key: 'FoldedBoardsState',
  default: [],
  effects_UNSTABLE: [persistAtom]
});
