import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { config } from 'src/config.env';
import { currency_data } from 'src/data/currency_data';
import { getLastCurrency, setLastCurrency } from 'src/utils/storageutils';

export enum LANGUAGE {
  KO = 'ko_KR',
  EN = 'en_US'
}

async function loadLocaleMessages(currentLanguage: string) {
  let lang = '';
  if (currentLanguage === 'ko') {
    lang = 'ko_KR';
  } else {
    lang = 'en_US';
  }
  // 해당 언어의 message 파일만 import 한다.
  const data = await import(
    /* webpackChunkName: "locale-chunk" */ `./locales/${lang}.json`
  );
  const locales = data.default;
  return { [currentLanguage]: locales };
}

export let $: any = () => {};

export async function i18nInit(currentLanguage: string) {
  return i18n
    .use(initReactI18next)
    .init({
      debug: config.devMode,
      load: 'currentOnly',
      interpolation: {
        escapeValue: true,
        prefix: '{',
        suffix: '}'
      },
      lng: currentLanguage,
      fallbackLng: LANGUAGE.EN,
      resources: await loadLocaleMessages(currentLanguage)
    })
    .then((t) => {
      $ = t;
    });
}

export function i18nInitCurrency(nativeLang: string) {
  const countryId = getCountryCodeFromLocale(nativeLang);
  const currencies = currency_data.country_currency;
  const item = currencies.find((item) => item.country_id == countryId);
  const currency = item?.currency ?? 'USD';
  getLastCurrency();

  if (!getLastCurrency()) {
    setLastCurrency(currency);
  }
}

export function getCurrencies() {
  return [];
}

export const getCountryCodeFromLocale = (locale: string) => {
  const splitted = locale.split('-');
  if (splitted && splitted.length > 1) return splitted[1];
  else return null;
};
