import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// 대문 페이지 와 공사중 공지사항 연결
const Index = Loader(lazy(() => import('src/pages/Index')));
// Main - Post 탐색 (카드 단위)
const Main = Loader(lazy(() => import('src/pages/Main')));
// My Plan 플랜
const Plan = Loader(lazy(() => import('src/pages/Plan')));
// Home 사용자 페이지
const Home = Loader(lazy(() => import('src/pages/Home')));

const Invitation = Loader(lazy(() => import('src/pages/Invitation')));
// Status
const Status404 = Loader(lazy(() => import('src/components/Status/Status404')));
const Status500 = Loader(lazy(() => import('src/components/Status/Status500')));
const StatusComingSoon = Loader(
  lazy(() => import('src/components/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/components/Status/Maintenance'))
);
// sign
const Logout = Loader(lazy(() => import('src/pages/Logout')));

const excluded_keyword = [
  'admin',
  'chat',
  'home',
  'plan',
  'place',
  'plus',
  'system',
  'sign',
  'status',
  'search',
  'setting',
  'settings',
  'trip',
  'travel',
  'user',
  'where',
  '-서비스이름',
  'pluswhere',
  'pluswhere-com',
  'pluswhere_com',
  'pluswhere.com'
];

const routes: RouteObject[] = [
  {
    path: '',
    children: [
      {
        path: '/',
        element: <Index />
      },
      {
        path: '/main',
        element: <Main />
      },
      {
        path: '/main/:menu',
        element: <Main />
      },
      {
        path: '/plan',
        children: [
          {
            path: ':code',
            element: <Plan />
          }
        ]
      },
      {
        path: '/plan',
        children: [
          {
            path: ':code/task/:taskCode',
            element: <Plan />
          }
        ]
      },
      {
        path: '/invitation',
        children: [
          {
            path: '',
            element: <Invitation />
          }
        ]
      },
      {
        path: '/logout',
        element: <Logout />
      },
      {
        path: '/status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: ':username',
        element: <Home />
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  }
];

export default routes;
