import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useRecoilState } from 'recoil';
import { useRefreshMutation } from 'src/apis/authApi';
import { ServerResponse } from 'src/apis/response/ServerResponse';
import { LoginInfoState } from 'src/states/my_info';
import { User } from 'src/states/user';
import {
  getAccessToken,
  removeAccessToken,
  setAccessToken
} from 'src/utils/storageutils';

export default function RefreshToken() {
  let navigate = useNavigate();
  let location = useLocation();
  const [loginState, setLoginState] = useRecoilState(LoginInfoState);
  const { mutateAsync: refresh } = useRefreshMutation();

  const check = async () => {
    try {
      await refresh().then((resultData: ServerResponse<any>) => {
        console.log('RefreshToken result:', resultData);
        if (resultData.statusCode === 200) {
          const user: User = resultData.data.user;
          setLoginState({
            isSignedIn: true,
            userInfo: user
          });
          setAccessToken(resultData.data.access_token);
        } else {
          // 권한 오류일 때만 로그인을 푼다.
          if (resultData.statusCode === 403) {
            logout();
          }
          console.log('RefreshToken statusCode not 200:', resultData);
        }
      });
    } catch (error) {
      // 서버 에러일 경우는 로그인을 풀지 않는다
      console.log('RefreshToken error:', error);
      // logout();
    }
  };

  const logout = () => {
    navigate('/logout');
  };

  useEffect(() => {
    if (loginState.isSignedIn) {
      // 로그인 했을 때만 리프레쉬 처리
      if (location.pathname != '/logout') {
        const token = getAccessToken();
        if (token) {
          check();
        }
      }
    }
  }, [location.pathname]);

  return <></>;
}
