export const currency_data = {
  currency_symbol: {
    url: {
      prefix: 'https://flagcdn.com/16x12/',
      suffix: '.png'
    }
  },
  currency: [
    {
      id: 'ALL',
      code: 'ALL',
      country_code2: 'AL',
      country: 'Albania Lek',
      code2000: 'Lek',
      arial_unicode_ms: 'Lek',
      unicode_decimal: '76, 101, 107',
      unicode_hex: '4c, 65, 6b',
      info: null,
      isUse: false
    },
    {
      id: 'AFN',
      code: 'AFN',
      country_code2: 'AF',
      country: 'Afghanistan Afghani',
      code2000: '؋',
      arial_unicode_ms: '؋',
      unicode_decimal: 1547,
      unicode_hex: '60b',
      info: null,
      isUse: false
    },
    {
      id: 'ARS',
      code: 'ARS',
      country_code2: 'AR',
      country: 'Argentina Peso',
      code2000: '$',
      arial_unicode_ms: '$',
      unicode_decimal: 36,
      unicode_hex: 24,
      info: 'Standard dollar sign is generally used to signify peso amounts.',
      isUse: false
    },
    {
      id: 'AWG',
      code: 'AWG',
      country_code2: 'AW',
      country: 'Aruba Guilder',
      code2000: 'ƒ',
      arial_unicode_ms: 'ƒ',
      unicode_decimal: 402,
      unicode_hex: 192,
      info: null,
      isUse: false
    },
    {
      id: 'AUD',
      code: 'AUD',
      country_code2: 'AU',
      country: 'Australia Dollar',
      code2000: '$',
      arial_unicode_ms: '$',
      unicode_decimal: 36,
      unicode_hex: 24,
      info: null,
      isUse: true
    },
    {
      id: 'AZN',
      code: 'AZN',
      country_code2: 'AZ',
      country: 'Azerbaijan Manat',
      code2000: '₼',
      arial_unicode_ms: '₼',
      unicode_decimal: 8380,
      unicode_hex: '20bc',
      info: null,
      isUse: false
    },
    {
      id: 'BSD',
      code: 'BSD',
      country_code2: 'BS',
      country: 'Bahamas Dollar',
      code2000: '$',
      arial_unicode_ms: '$',
      unicode_decimal: 36,
      unicode_hex: 24,
      info: null,
      isUse: false
    },
    {
      id: 'BBD',
      code: 'BBD',
      country_code2: 'BB',
      country: 'Barbados Dollar',
      code2000: '$',
      arial_unicode_ms: '$',
      unicode_decimal: 36,
      unicode_hex: 24,
      info: null,
      isUse: false
    },
    {
      id: 'BYR',
      code: 'BYR',
      country_code2: 'BY',
      country: 'Belarus Ruble',
      code2000: 'Br',
      arial_unicode_ms: 'Br',
      unicode_decimal: '66, 114',
      unicode_hex: '42, 72',
      info: null,
      isUse: false
    },
    {
      id: 'BZD',
      code: 'BZD',
      country_code2: 'BZ',
      country: 'Belize Dollar',
      code2000: 'BZ$',
      arial_unicode_ms: 'BZ$',
      unicode_decimal: '66, 90, 36',
      unicode_hex: '42, 5a, 24',
      info: null,
      isUse: false
    },
    {
      id: 'BMD',
      code: 'BMD',
      country_code2: 'BM',
      country: 'Bermuda Dollar',
      code2000: '$',
      arial_unicode_ms: '$',
      unicode_decimal: 36,
      unicode_hex: 24,
      info: null,
      isUse: false
    },
    {
      id: 'BOB',
      code: 'BOB',
      country_code2: 'BO',
      country: 'Bolivia Bolíviano',
      code2000: '$b',
      arial_unicode_ms: '$b',
      unicode_decimal: '36, 98',
      unicode_hex: '24, 62',
      info: null,
      isUse: false
    },
    {
      id: 'BAM',
      code: 'BAM',
      country_code2: 'BA',
      country: 'Bosnia and Herzegovina Convertible Mark',
      code2000: 'KM',
      arial_unicode_ms: 'KM',
      unicode_decimal: '75, 77',
      unicode_hex: '4b, 4d',
      info: null,
      isUse: false
    },
    {
      id: 'BWP',
      code: 'BWP',
      country_code2: 'BW',
      country: 'Botswana Pula',
      code2000: 'P',
      arial_unicode_ms: 'P',
      unicode_decimal: 80,
      unicode_hex: 50,
      info: null,
      isUse: false
    },
    {
      id: 'BGN',
      code: 'BGN',
      country_code2: 'BG',
      country: 'Bulgaria Lev',
      code2000: 'лв',
      arial_unicode_ms: 'лв',
      unicode_decimal: '1083, 1074',
      unicode_hex: '43b, 432',
      info: null,
      isUse: false
    },
    {
      id: 'BRL',
      code: 'BRL',
      country_code2: 'BR',
      country: 'Brazil Real',
      code2000: 'R$',
      arial_unicode_ms: 'R$',
      unicode_decimal: '82, 36',
      unicode_hex: '52, 24',
      info: "This symbol is composed of two sequential characters: an upper case 'R' followed by standard dollar sign '$'.",
      isUse: true
    },
    {
      id: 'BND',
      code: 'BND',
      country_code2: 'BN',
      country: 'Brunei Darussalam Dollar',
      code2000: '$',
      arial_unicode_ms: '$',
      unicode_decimal: 36,
      unicode_hex: 24,
      info: null,
      isUse: true
    },
    {
      id: 'KHR',
      code: 'KHR',
      country_code2: 'KH',
      country: 'Cambodia Riel',
      code2000: '៛',
      arial_unicode_ms: '៛',
      unicode_decimal: 6107,
      unicode_hex: '17db',
      info: null,
      isUse: true
    },
    {
      id: 'CAD',
      code: 'CAD',
      country_code2: 'CA',
      country: 'Canada Dollar',
      code2000: '$',
      arial_unicode_ms: '$',
      unicode_decimal: 36,
      unicode_hex: 24,
      info: null,
      isUse: true
    },
    {
      id: 'KYD',
      code: 'KYD',
      country_code2: 'KY',
      country: 'Cayman Islands Dollar',
      code2000: '$',
      arial_unicode_ms: '$',
      unicode_decimal: 36,
      unicode_hex: 24,
      info: null,
      isUse: false
    },
    {
      id: 'CLP',
      code: 'CLP',
      country_code2: 'CL',
      country: 'Chile Peso',
      code2000: '$',
      arial_unicode_ms: '$',
      unicode_decimal: 36,
      unicode_hex: 24,
      info: 'Standard dollar sign is generally used to signify peso amounts.',
      isUse: true
    },
    {
      id: 'CNY',
      code: 'CNY',
      country_code2: 'CN',
      country: 'China Yuan Renminbi',
      code2000: '¥',
      arial_unicode_ms: '¥',
      unicode_decimal: 165,
      unicode_hex: 'a5',
      info: 'Same symbol as JPY Japan Yen.',
      isUse: true
    },
    {
      id: 'COP',
      code: 'COP',
      country_code2: 'CO',
      country: 'Colombia Peso',
      code2000: '$',
      arial_unicode_ms: '$',
      unicode_decimal: 36,
      unicode_hex: 24,
      info: null,
      isUse: true
    },
    {
      id: 'CRC',
      code: 'CRC',
      country_code2: 'CR',
      country: 'Costa Rica Colon',
      code2000: '₡',
      arial_unicode_ms: '₡',
      unicode_decimal: 8353,
      unicode_hex: '20a1',
      info: null,
      isUse: false
    },
    {
      id: 'HRK',
      code: 'HRK',
      country_code2: 'HR',
      country: 'Croatia Kuna',
      code2000: 'kn',
      arial_unicode_ms: 'kn',
      unicode_decimal: '107, 110',
      unicode_hex: '6b, 6e',
      info: null,
      isUse: false
    },
    {
      id: 'CUP',
      code: 'CUP',
      country_code2: 'CU',
      country: 'Cuba Peso',
      code2000: '₱',
      arial_unicode_ms: '₱',
      unicode_decimal: 8369,
      unicode_hex: '20b1',
      info: null,
      isUse: false
    },
    {
      id: 'CZK',
      code: 'CZK',
      country_code2: 'CZ',
      country: 'Czech Republic Koruna',
      code2000: 'Kč',
      arial_unicode_ms: 'Kč',
      unicode_decimal: '75, 269',
      unicode_hex: '4b, 10d',
      info: null,
      isUse: true
    },
    {
      id: 'DKK',
      code: 'DKK',
      country_code2: 'DK',
      country: 'Denmark Krone',
      code2000: 'kr',
      arial_unicode_ms: 'kr',
      unicode_decimal: '107, 114',
      unicode_hex: '6b, 72',
      info: "This symbol is composed of two sequential characters: a lower case 'k' followed by a lower case 'r'.",
      isUse: true
    },
    {
      id: 'DOP',
      code: 'DOP',
      country_code2: 'DO',
      country: 'Dominican Republic Peso',
      code2000: 'RD$',
      arial_unicode_ms: 'RD$',
      unicode_decimal: '82, 68, 36',
      unicode_hex: '52, 44, 24',
      info: null,
      isUse: false
    },
    {
      id: 'EGP',
      code: 'EGP',
      country_code2: 'EG',
      country: 'Egypt Pound',
      code2000: '£',
      arial_unicode_ms: '£',
      unicode_decimal: 163,
      unicode_hex: 'a3',
      info: null,
      isUse: true
    },
    {
      id: 'SVC',
      code: 'SVC',
      country_code2: 'SV',
      country: 'El Salvador Colon',
      code2000: '$',
      arial_unicode_ms: '$',
      unicode_decimal: 36,
      unicode_hex: 24,
      info: null,
      isUse: false
    },
    {
      id: 'EUR',
      code: 'EUR',
      country_code2: 'EU',
      country: 'Euro Member Countries',
      code2000: '€',
      arial_unicode_ms: '€',
      unicode_decimal: 8364,
      unicode_hex: '20ac',
      info: null,
      isUse: true
    },
    {
      id: 'FKP',
      code: 'FKP',
      country_code2: 'FK',
      country: 'Falkland Islands (Malvinas) Pound',
      code2000: '£',
      arial_unicode_ms: '£',
      unicode_decimal: 163,
      unicode_hex: 'a3',
      info: null,
      isUse: false
    },
    {
      id: 'FJD',
      code: 'FJD',
      country_code2: 'FJ',
      country: 'Fiji Dollar',
      code2000: '$',
      arial_unicode_ms: '$',
      unicode_decimal: 36,
      unicode_hex: 24,
      info: null,
      isUse: true
    },
    {
      id: 'GHS',
      code: 'GHS',
      country_code2: 'GH',
      country: 'Ghana Cedi',
      code2000: '¢',
      arial_unicode_ms: '¢',
      unicode_decimal: 162,
      unicode_hex: 'a2',
      info: null,
      isUse: false
    },
    {
      id: 'GIP',
      code: 'GIP',
      country_code2: 'GI',
      country: 'Gibraltar Pound',
      code2000: '£',
      arial_unicode_ms: '£',
      unicode_decimal: 163,
      unicode_hex: 'a3',
      info: null,
      isUse: false
    },
    {
      id: 'GTQ',
      code: 'GTQ',
      country_code2: 'GT',
      country: 'Guatemala Quetzal',
      code2000: 'Q',
      arial_unicode_ms: 'Q',
      unicode_decimal: 81,
      unicode_hex: 51,
      info: null,
      isUse: false
    },
    {
      id: 'GYD',
      code: 'GYD',
      country_code2: 'GY',
      country: 'Guyana Dollar',
      code2000: '$',
      arial_unicode_ms: '$',
      unicode_decimal: 36,
      unicode_hex: 24,
      info: null,
      isUse: false
    },
    {
      id: 'HNL',
      code: 'HNL',
      country_code2: 'HN',
      country: 'Honduras Lempira',
      code2000: 'L',
      arial_unicode_ms: 'L',
      unicode_decimal: 76,
      unicode_hex: '4c',
      info: null,
      isUse: false
    },
    {
      id: 'HKD',
      code: 'HKD',
      country_code2: 'HK',
      country: 'Hong Kong Dollar',
      code2000: '$',
      arial_unicode_ms: '$',
      unicode_decimal: 36,
      unicode_hex: 24,
      info: 'Standard dollar sign is generally used. Sometimes written HK$ for clarity if used alongside US Dollar prices.',
      isUse: true
    },
    {
      id: 'HUF',
      code: 'HUF',
      country_code2: 'HU',
      country: 'Hungary Forint',
      code2000: 'Ft',
      arial_unicode_ms: 'Ft',
      unicode_decimal: '70, 116',
      unicode_hex: '46, 74',
      info: null,
      isUse: true
    },
    {
      id: 'ISK',
      code: 'ISK',
      country_code2: 'IS',
      country: 'Iceland Krona',
      code2000: 'kr',
      arial_unicode_ms: 'kr',
      unicode_decimal: '107, 114',
      unicode_hex: '6b, 72',
      info: null,
      isUse: false
    },
    {
      id: 'INR',
      code: 'INR',
      country_code2: 'IN',
      country: 'India Rupee',
      code2000: '₹',
      arial_unicode_ms: '₹',
      unicode_decimal: 8377,
      unicode_hex: '20b9',
      info: 'Introduced July 2010 - no font information available at this time.',
      isUse: true
    },
    {
      id: 'IDR',
      code: 'IDR',
      country_code2: 'ID',
      country: 'Indonesia Rupiah',
      code2000: 'Rp',
      arial_unicode_ms: 'Rp',
      unicode_decimal: '82, 112',
      unicode_hex: '52, 70',
      info: null,
      isUse: true
    },
    {
      id: 'IRR',
      code: 'IRR',
      country_code2: 'IR',
      country: 'Iran Rial',
      code2000: '﷼',
      arial_unicode_ms: '﷼',
      unicode_decimal: 65020,
      unicode_hex: 'fdfc',
      info: null,
      isUse: false
    },
    {
      id: 'IMP',
      code: 'IMP',
      country_code2: 'IM',
      country: 'Isle of Man Pound',
      code2000: '£',
      arial_unicode_ms: '£',
      unicode_decimal: 163,
      unicode_hex: 'a3',
      info: null,
      isUse: false
    },
    {
      id: 'ILS',
      code: 'ILS',
      country_code2: 'IL',
      country: 'Israel Shekel',
      code2000: '₪',
      arial_unicode_ms: '₪',
      unicode_decimal: 8362,
      unicode_hex: '20aa',
      info: null,
      isUse: true
    },
    {
      id: 'JMD',
      code: 'JMD',
      country_code2: 'JM',
      country: 'Jamaica Dollar',
      code2000: 'J$',
      arial_unicode_ms: 'J$',
      unicode_decimal: '74, 36',
      unicode_hex: '4a, 24',
      info: null,
      isUse: false
    },
    {
      id: 'JPY',
      code: 'JPY',
      country_code2: 'JP',
      country: 'Japan Yen',
      code2000: '¥',
      arial_unicode_ms: '¥',
      unicode_decimal: 165,
      unicode_hex: 'a5',
      info: 'Same symbol as CNY China Yuan Renminbi.',
      isUse: true
    },
    {
      id: 'JEP',
      code: 'JEP',
      country_code2: 'JE',
      country: 'Jersey Pound',
      code2000: '£',
      arial_unicode_ms: '£',
      unicode_decimal: 163,
      unicode_hex: 'a3',
      info: null,
      isUse: false
    },
    {
      id: 'KZT',
      code: 'KZT',
      country_code2: 'KZ',
      country: 'Kazakhstan Tenge',
      code2000: 'лв',
      arial_unicode_ms: 'лв',
      unicode_decimal: '1083, 1074',
      unicode_hex: '43b, 432',
      info: null,
      isUse: true
    },
    {
      id: 'KPW',
      code: 'KPW',
      country_code2: 'KP',
      country: 'Korea (North) Won',
      code2000: '₩',
      arial_unicode_ms: '₩',
      unicode_decimal: 8361,
      unicode_hex: '20a9',
      info: null,
      isUse: false
    },
    {
      id: 'KRW',
      code: 'KRW',
      country_code2: 'KR',
      country: 'Korea (South) Won',
      code2000: '₩',
      arial_unicode_ms: '₩',
      unicode_decimal: 8361,
      unicode_hex: '20a9',
      info: null,
      isUse: true
    },
    {
      id: 'KGS',
      code: 'KGS',
      country_code2: 'KG',
      country: 'Kyrgyzstan Som',
      code2000: 'лв',
      arial_unicode_ms: 'лв',
      unicode_decimal: '1083, 1074',
      unicode_hex: '43b, 432',
      info: null,
      isUse: false
    },
    {
      id: 'LAK',
      code: 'LAK',
      country_code2: 'LA',
      country: 'Laos Kip',
      code2000: '₭',
      arial_unicode_ms: '₭',
      unicode_decimal: 8365,
      unicode_hex: '20ad',
      info: null,
      isUse: false
    },
    {
      id: 'LBP',
      code: 'LBP',
      country_code2: 'LB',
      country: 'Lebanon Pound',
      code2000: '£',
      arial_unicode_ms: '£',
      unicode_decimal: 163,
      unicode_hex: 'a3',
      info: null,
      isUse: false
    },
    {
      id: 'LRD',
      code: 'LRD',
      country_code2: 'LR',
      country: 'Liberia Dollar',
      code2000: '$',
      arial_unicode_ms: '$',
      unicode_decimal: 36,
      unicode_hex: 24,
      info: null,
      isUse: false
    },
    {
      id: 'MKD',
      code: 'MKD',
      country_code2: 'MK',
      country: 'Macedonia Denar',
      code2000: 'ден',
      arial_unicode_ms: 'ден',
      unicode_decimal: '1076, 1077, 1085',
      unicode_hex: '434, 435, 43d',
      info: null,
      isUse: false
    },
    {
      id: 'MYR',
      code: 'MYR',
      country_code2: 'MY',
      country: 'Malaysia Ringgit',
      code2000: 'RM',
      arial_unicode_ms: 'RM',
      unicode_decimal: '82, 77',
      unicode_hex: '52, 4d',
      info: null,
      isUse: true
    },
    {
      id: 'MUR',
      code: 'MUR',
      country_code2: 'MU',
      country: 'Mauritius Rupee',
      code2000: '₨',
      arial_unicode_ms: '₨',
      unicode_decimal: 8360,
      unicode_hex: '20a8',
      info: null,
      isUse: false
    },
    {
      id: 'MXN',
      code: 'MXN',
      country_code2: 'MX',
      country: 'Mexico Peso',
      code2000: '$',
      arial_unicode_ms: '$',
      unicode_decimal: 36,
      unicode_hex: 24,
      info: 'Standard dollar sign is generally used to signify peso amounts.',
      isUse: true
    },
    {
      id: 'MNT',
      code: 'MNT',
      country_code2: 'MN',
      country: 'Mongolia Tughrik',
      code2000: '₮',
      arial_unicode_ms: '₮',
      unicode_decimal: 8366,
      unicode_hex: '20ae',
      info: null,
      isUse: true
    },
    {
      id: 'MZN',
      code: 'MZN',
      country_code2: 'MZ',
      country: 'Mozambique Metical',
      code2000: 'MT',
      arial_unicode_ms: 'MT',
      unicode_decimal: '77, 84',
      unicode_hex: '4d, 54',
      info: null,
      isUse: false
    },
    {
      id: 'NAD',
      code: 'NAD',
      country_code2: 'NA',
      country: 'Namibia Dollar',
      code2000: '$',
      arial_unicode_ms: '$',
      unicode_decimal: 36,
      unicode_hex: 24,
      info: null,
      isUse: false
    },
    {
      id: 'NPR',
      code: 'NPR',
      country_code2: 'NP',
      country: 'Nepal Rupee',
      code2000: '₨',
      arial_unicode_ms: '₨',
      unicode_decimal: 8360,
      unicode_hex: '20a8',
      info: null,
      isUse: true
    },
    {
      id: 'NZD',
      code: 'NZD',
      country_code2: 'NZ',
      country: 'New Zealand Dollar',
      code2000: '$',
      arial_unicode_ms: '$',
      unicode_decimal: 36,
      unicode_hex: 24,
      info: null,
      isUse: true
    },
    {
      id: 'NIO',
      code: 'NIO',
      country_code2: 'NI',
      country: 'Nicaragua Cordoba',
      code2000: 'C$',
      arial_unicode_ms: 'C$',
      unicode_decimal: '67, 36',
      unicode_hex: '43, 24',
      info: null,
      isUse: false
    },
    {
      id: 'NGN',
      code: 'NGN',
      country_code2: 'NG',
      country: 'Nigeria Naira',
      code2000: '₦',
      arial_unicode_ms: '₦',
      unicode_decimal: 8358,
      unicode_hex: '20a6',
      info: null,
      isUse: false
    },
    {
      id: 'NOK',
      code: 'NOK',
      country_code2: 'NO',
      country: 'Norway Krone',
      code2000: 'kr',
      arial_unicode_ms: 'kr',
      unicode_decimal: '107, 114',
      unicode_hex: '6b, 72',
      info: null,
      isUse: true
    },
    {
      id: 'OMR',
      code: 'OMR',
      country_code2: 'OM',
      country: 'Oman Rial',
      code2000: '﷼',
      arial_unicode_ms: '﷼',
      unicode_decimal: 65020,
      unicode_hex: 'fdfc',
      info: null,
      isUse: true
    },
    {
      id: 'PKR',
      code: 'PKR',
      country_code2: 'PK',
      country: 'Pakistan Rupee',
      code2000: '₨',
      arial_unicode_ms: '₨',
      unicode_decimal: 8360,
      unicode_hex: '20a8',
      info: null,
      isUse: true
    },
    {
      id: 'PAB',
      code: 'PAB',
      country_code2: 'PA',
      country: 'Panama Balboa',
      code2000: 'B/.',
      arial_unicode_ms: 'B/.',
      unicode_decimal: '66, 47, 46',
      unicode_hex: '42, 2f, 2e',
      info: null,
      isUse: false
    },
    {
      id: 'PYG',
      code: 'PYG',
      country_code2: 'PY',
      country: 'Paraguay Guarani',
      code2000: 'Gs',
      arial_unicode_ms: 'Gs',
      unicode_decimal: '71, 115',
      unicode_hex: '47, 73',
      info: null,
      isUse: false
    },
    {
      id: 'PEN',
      code: 'PEN',
      country_code2: 'PE',
      country: 'Peru Sol',
      code2000: 'S/.',
      arial_unicode_ms: 'S/.',
      unicode_decimal: '83, 47, 46',
      unicode_hex: '53, 2f, 2e',
      info: "This symbol is composed of three sequential characters: an upper case 'S' followed by a slash '/' followed by a dot '.'.",
      isUse: false
    },
    {
      id: 'PHP',
      code: 'PHP',
      country_code2: 'PH',
      country: 'Philippines Peso',
      code2000: '₱',
      arial_unicode_ms: '₱',
      unicode_decimal: 8369,
      unicode_hex: '20b1',
      info: null,
      isUse: true
    },
    {
      id: 'PLN',
      code: 'PLN',
      country_code2: 'PL',
      country: 'Poland Zloty',
      code2000: 'zł',
      arial_unicode_ms: 'zł',
      unicode_decimal: '122, 322',
      unicode_hex: '7a, 142',
      info: null,
      isUse: true
    },
    {
      id: 'QAR',
      code: 'QAR',
      country_code2: 'QA',
      country: 'Qatar Riyal',
      code2000: '﷼',
      arial_unicode_ms: '﷼',
      unicode_decimal: 65020,
      unicode_hex: 'fdfc',
      info: null,
      isUse: true
    },
    {
      id: 'RON',
      code: 'RON',
      country_code2: 'RO',
      country: 'Romania Leu',
      code2000: 'lei',
      arial_unicode_ms: 'lei',
      unicode_decimal: '108, 101, 105',
      unicode_hex: '6c, 65, 69',
      info: null,
      isUse: true
    },
    {
      id: 'RUB',
      code: 'RUB',
      country_code2: 'RU',
      country: 'Russia Ruble',
      code2000: '₽',
      arial_unicode_ms: '₽',
      unicode_decimal: 8381,
      unicode_hex: '20bd',
      info: null,
      isUse: true
    },
    {
      id: 'SHP',
      code: 'SHP',
      country_code2: 'SH',
      country: 'Saint Helena Pound',
      code2000: '£',
      arial_unicode_ms: '£',
      unicode_decimal: 163,
      unicode_hex: 'a3',
      info: null,
      isUse: false
    },
    {
      id: 'SAR',
      code: 'SAR',
      country_code2: 'SA',
      country: 'Saudi Arabia Riyal',
      code2000: '﷼',
      arial_unicode_ms: '﷼',
      unicode_decimal: 65020,
      unicode_hex: 'fdfc',
      info: null,
      isUse: true
    },
    {
      id: 'RSD',
      code: 'RSD',
      country_code2: 'RS',
      country: 'Serbia Dinar',
      code2000: 'Дин.',
      arial_unicode_ms: 'Дин.',
      unicode_decimal: '1044, 1080, 1085, 46',
      unicode_hex: '414, 438, 43d, 2e',
      info: null,
      isUse: false
    },
    {
      id: 'SCR',
      code: 'SCR',
      country_code2: 'SC',
      country: 'Seychelles Rupee',
      code2000: '₨',
      arial_unicode_ms: '₨',
      unicode_decimal: 8360,
      unicode_hex: '20a8',
      info: null,
      isUse: false
    },
    {
      id: 'SGD',
      code: 'SGD',
      country_code2: 'SG',
      country: 'Singapore Dollar',
      code2000: '$',
      arial_unicode_ms: '$',
      unicode_decimal: 36,
      unicode_hex: 24,
      info: null,
      isUse: true
    },
    {
      id: 'SBD',
      code: 'SBD',
      country_code2: 'SB',
      country: 'Solomon Islands Dollar',
      code2000: '$',
      arial_unicode_ms: '$',
      unicode_decimal: 36,
      unicode_hex: 24,
      info: null,
      isUse: false
    },
    {
      id: 'SOS',
      code: 'SOS',
      country_code2: 'SO',
      country: 'Somalia Shilling',
      code2000: 'S',
      arial_unicode_ms: 'S',
      unicode_decimal: 83,
      unicode_hex: 53,
      info: null,
      isUse: false
    },
    {
      id: 'ZAR',
      code: 'ZAR',
      country_code2: 'ZA',
      country: 'South Africa Rand',
      code2000: 'R',
      arial_unicode_ms: 'R',
      unicode_decimal: 82,
      unicode_hex: 52,
      info: null,
      isUse: true
    },
    {
      id: 'LKR',
      code: 'LKR',
      country_code2: 'LK',
      country: 'Sri Lanka Rupee',
      code2000: '₨',
      arial_unicode_ms: '₨',
      unicode_decimal: 8360,
      unicode_hex: '20a8',
      info: null,
      isUse: true
    },
    {
      id: 'SEK',
      code: 'SEK',
      country_code2: 'SE',
      country: 'Sweden Krona',
      code2000: 'kr',
      arial_unicode_ms: 'kr',
      unicode_decimal: '107, 114',
      unicode_hex: '6b, 72',
      info: "This symbol is composed of two sequential characters: a lower case 'k' followed by a lower case 'r'.",
      isUse: true
    },
    {
      id: 'CHF',
      code: 'CHF',
      country_code2: 'CH',
      country: 'Switzerland Franc',
      code2000: 'CHF',
      arial_unicode_ms: 'CHF',
      unicode_decimal: '67, 72, 70',
      unicode_hex: '43, 48, 46',
      info: null,
      isUse: true
    },
    {
      id: 'SRD',
      code: 'SRD',
      country_code2: 'SR',
      country: 'Suriname Dollar',
      code2000: '$',
      arial_unicode_ms: '$',
      unicode_decimal: 36,
      unicode_hex: 24,
      info: null,
      isUse: false
    },
    {
      id: 'SYP',
      code: 'SYP',
      country_code2: 'SY',
      country: 'Syria Pound',
      code2000: '£',
      arial_unicode_ms: '£',
      unicode_decimal: 163,
      unicode_hex: 'a3',
      info: null,
      isUse: false
    },
    {
      id: 'TWD',
      code: 'TWD',
      country_code2: 'TW',
      country: 'Taiwan New Dollar',
      code2000: 'NT$',
      arial_unicode_ms: 'NT$',
      unicode_decimal: '78, 84, 36',
      unicode_hex: '4e, 54, 24',
      info: 'Unconfirmed.',
      isUse: true
    },
    {
      id: 'THB',
      code: 'THB',
      country_code2: 'TH',
      country: 'Thailand Baht',
      code2000: '฿',
      arial_unicode_ms: '฿',
      unicode_decimal: 3647,
      unicode_hex: 'e3f',
      info: null,
      isUse: true
    },
    {
      id: 'TTD',
      code: 'TTD',
      country_code2: 'TT',
      country: 'Trinidad and Tobago Dollar',
      code2000: 'TT$',
      arial_unicode_ms: 'TT$',
      unicode_decimal: '84, 84, 36',
      unicode_hex: '54, 54, 24',
      info: null,
      isUse: false
    },
    {
      id: 'TRY',
      code: 'TRY',
      country_code2: 'TR',
      country: 'Turkey Lira',
      code2000: '₺',
      arial_unicode_ms: '₺',
      unicode_decimal: 8378,
      unicode_hex: '20ba',
      info: 'Introduced March 2012 - no font information available at this time.',
      isUse: true
    },
    {
      id: 'TVD',
      code: 'TVD',
      country_code2: 'TV',
      country: 'Tuvalu Dollar',
      code2000: '$',
      arial_unicode_ms: '$',
      unicode_decimal: 36,
      unicode_hex: 24,
      info: null,
      isUse: false
    },
    {
      id: 'UAH',
      code: 'UAH',
      country_code2: 'UA',
      country: 'Ukraine Hryvnia',
      code2000: '₴',
      arial_unicode_ms: '₴',
      unicode_decimal: 8372,
      unicode_hex: '20b4',
      info: null,
      isUse: false
    },
    {
      id: 'GBP',
      code: 'GBP',
      country_code2: 'GB',
      country: 'United Kingdom Pound',
      code2000: '£',
      arial_unicode_ms: '£',
      unicode_decimal: 163,
      unicode_hex: 'a3',
      info: null,
      isUse: true
    },
    {
      id: 'USD',
      code: 'USD',
      country_code2: 'US',
      country: 'United States Dollar',
      code2000: '$',
      arial_unicode_ms: '$',
      unicode_decimal: 36,
      unicode_hex: 24,
      info: null,
      isUse: true
    },
    {
      id: 'UYU',
      code: 'UYU',
      country_code2: 'UY',
      country: 'Uruguay Peso',
      code2000: '$U',
      arial_unicode_ms: '$U',
      unicode_decimal: '36, 85',
      unicode_hex: '24, 55',
      info: null,
      isUse: false
    },
    {
      id: 'UZS',
      code: 'UZS',
      country_code2: 'UZ',
      country: 'Uzbekistan Som',
      code2000: 'лв',
      arial_unicode_ms: 'лв',
      unicode_decimal: '1083, 1074',
      unicode_hex: '43b, 432',
      info: null,
      isUse: true
    },
    {
      id: 'VEF',
      code: 'VEF',
      country_code2: 'VE',
      country: 'Venezuela Bolívar',
      code2000: 'Bs',
      arial_unicode_ms: 'Bs',
      unicode_decimal: '66, 115',
      unicode_hex: '42, 73',
      info: null,
      isUse: false
    },
    {
      id: 'VND',
      code: 'VND',
      country_code2: 'VN',
      country: 'Viet Nam Dong',
      code2000: '₫',
      arial_unicode_ms: '₫',
      unicode_decimal: 8363,
      unicode_hex: '20ab',
      info: null,
      isUse: true
    },
    {
      id: 'YER',
      code: 'YER',
      country_code2: 'YE',
      country: 'Yemen Rial',
      code2000: '﷼',
      arial_unicode_ms: '﷼',
      unicode_decimal: 65020,
      unicode_hex: 'fdfc',
      info: null,
      isUse: false
    },
    {
      id: 'ZWD',
      code: 'ZWD',
      country_code2: 'ZW',
      country: 'Zimbabwe Dollar',
      code2000: 'Z$',
      arial_unicode_ms: 'Z$',
      unicode_decimal: '90, 36',
      unicode_hex: '5a, 24',
      info: null,
      isUse: false
    }
  ],
  country_currency: [
    {
      country_id: 'AC',
      country_name: 'Ascension Island',
      currency: 'USD'
    },
    {
      country_id: 'AD',
      country_name: 'Andorra',
      currency: 'EUR'
    },
    {
      country_id: 'AE',
      country_name: 'United Arab Emirates',
      currency: 'AED'
    },
    {
      country_id: 'AF',
      country_name: 'Afghanistan',
      currency: 'AFN'
    },
    {
      country_id: 'AG',
      country_name: 'Antigua And Barbuda',
      currency: 'XCD'
    },
    {
      country_id: 'AI',
      country_name: 'Anguilla',
      currency: 'XCD'
    },
    {
      country_id: 'AL',
      country_name: 'Albania',
      currency: 'ALL'
    },
    {
      country_id: 'AM',
      country_name: 'Armenia',
      currency: 'AMD'
    },
    {
      country_id: 'AO',
      country_name: 'Angola',
      currency: 'AOA'
    },
    {
      country_id: 'AQ',
      country_name: 'Antarctica',
      currency: 'USD'
    },
    {
      country_id: 'AR',
      country_name: 'Argentina',
      currency: 'ARS'
    },
    {
      country_id: 'AS',
      country_name: 'American Samoa',
      currency: 'USD'
    },
    {
      country_id: 'AT',
      country_name: 'Austria',
      currency: 'EUR'
    },
    {
      country_id: 'AU',
      country_name: 'Australia',
      currency: 'AUD'
    },
    {
      country_id: 'AW',
      country_name: 'Aruba',
      currency: 'AWG'
    },
    {
      country_id: 'AX',
      country_name: 'Åland Islands',
      currency: 'EUR'
    },
    {
      country_id: 'AZ',
      country_name: 'Azerbaijan',
      currency: 'AZN'
    },
    {
      country_id: 'BA',
      country_name: 'Bosnia & Herzegovina',
      currency: 'BAM'
    },
    {
      country_id: 'BB',
      country_name: 'Barbados',
      currency: 'BBD'
    },
    {
      country_id: 'BD',
      country_name: 'Bangladesh',
      currency: 'BDT'
    },
    {
      country_id: 'BE',
      country_name: 'Belgium',
      currency: 'EUR'
    },
    {
      country_id: 'BF',
      country_name: 'Burkina Faso',
      currency: 'XOF'
    },
    {
      country_id: 'BG',
      country_name: 'Bulgaria',
      currency: 'BGN'
    },
    {
      country_id: 'BH',
      country_name: 'Bahrain',
      currency: 'BHD'
    },
    {
      country_id: 'BI',
      country_name: 'Burundi',
      currency: 'BIF'
    },
    {
      country_id: 'BJ',
      country_name: 'Benin',
      currency: 'XOF'
    },
    {
      country_id: 'BL',
      country_name: 'Saint Barthélemy',
      currency: 'EUR'
    },
    {
      country_id: 'BM',
      country_name: 'Bermuda',
      currency: 'BMD'
    },
    {
      country_id: 'BN',
      country_name: 'Brunei Darussalam',
      currency: 'BND'
    },
    {
      country_id: 'BO',
      country_name: 'Bolivia, Plurinational State Of',
      currency: 'BOB'
    },
    {
      country_id: 'BQ',
      country_name: 'Bonaire, Saint Eustatius And Saba',
      currency: 'USD'
    },
    {
      country_id: 'BR',
      country_name: 'Brazil',
      currency: 'BRL'
    },
    {
      country_id: 'BS',
      country_name: 'Bahamas',
      currency: 'BSD'
    },
    {
      country_id: 'BT',
      country_name: 'Bhutan',
      currency: 'INR'
    },
    {
      country_id: 'BV',
      country_name: 'Bouvet Island',
      currency: 'NOK'
    },
    {
      country_id: 'BW',
      country_name: 'Botswana',
      currency: 'BWP'
    },
    {
      country_id: 'BY',
      country_name: 'Belarus',
      currency: 'BYR'
    },
    {
      country_id: 'BZ',
      country_name: 'Belize',
      currency: 'BZD'
    },
    {
      country_id: 'CA',
      country_name: 'Canada',
      currency: 'CAD'
    },
    {
      country_id: 'CC',
      country_name: 'Cocos (Keeling) Islands',
      currency: 'AUD'
    },
    {
      country_id: 'CD',
      country_name: 'Democratic Republic Of Congo',
      currency: 'CDF'
    },
    {
      country_id: 'CF',
      country_name: 'Central African Republic',
      currency: 'XAF'
    },
    {
      country_id: 'CG',
      country_name: 'Republic Of Congo',
      currency: 'XAF'
    },
    {
      country_id: 'CH',
      country_name: 'Switzerland',
      currency: 'CHF'
    },
    {
      country_id: 'CI',
      country_name: 'Cote d Ivoire',
      currency: 'XOF'
    },
    {
      country_id: 'CK',
      country_name: 'Cook Islands',
      currency: 'NZD'
    },
    {
      country_id: 'CL',
      country_name: 'Chile',
      currency: 'CLP'
    },
    {
      country_id: 'CM',
      country_name: 'Cameroon',
      currency: 'XAF'
    },
    {
      country_id: 'CN',
      country_name: 'China',
      currency: 'CNY'
    },
    {
      country_id: 'CO',
      country_name: 'Colombia',
      currency: 'COP'
    },
    {
      country_id: 'CP',
      country_name: 'Clipperton Island',
      currency: 'EUR'
    },
    {
      country_id: 'CR',
      country_name: 'Costa Rica',
      currency: 'CRC'
    },
    {
      country_id: 'CU',
      country_name: 'Cuba',
      currency: 'CUP'
    },
    {
      country_id: 'CV',
      country_name: 'Cabo Verde',
      currency: 'CVE'
    },
    {
      country_id: 'CW',
      country_name: 'Curacao',
      currency: 'ANG'
    },
    {
      country_id: 'CX',
      country_name: 'Christmas Island',
      currency: 'AUD'
    },
    {
      country_id: 'CY',
      country_name: 'Cyprus',
      currency: 'EUR'
    },
    {
      country_id: 'CZ',
      country_name: 'Czech Republic',
      currency: 'CZK'
    },
    {
      country_id: 'DE',
      country_name: 'Germany',
      currency: 'EUR'
    },
    {
      country_id: 'DG',
      country_name: 'Diego Garcia',
      currency: 'USD'
    },
    {
      country_id: 'DJ',
      country_name: 'Djibouti',
      currency: 'DJF'
    },
    {
      country_id: 'DK',
      country_name: 'Denmark',
      currency: 'DKK'
    },
    {
      country_id: 'DM',
      country_name: 'Dominica',
      currency: 'XCD'
    },
    {
      country_id: 'DO',
      country_name: 'Dominican Republic',
      currency: 'DOP'
    },
    {
      country_id: 'DZ',
      country_name: 'Algeria',
      currency: 'DZD'
    },
    {
      country_id: 'EA',
      country_name: 'Ceuta, Mulilla',
      currency: 'EUR'
    },
    {
      country_id: 'EC',
      country_name: 'Ecuador',
      currency: 'USD'
    },
    {
      country_id: 'EE',
      country_name: 'Estonia',
      currency: 'EUR'
    },
    {
      country_id: 'EG',
      country_name: 'Egypt',
      currency: 'EGP'
    },
    {
      country_id: 'EH',
      country_name: 'Western Sahara',
      currency: 'MAD'
    },
    {
      country_id: 'ER',
      country_name: 'Eritrea',
      currency: 'ERN'
    },
    {
      country_id: 'ES',
      country_name: 'Spain',
      currency: 'EUR'
    },
    {
      country_id: 'ET',
      country_name: 'Ethiopia',
      currency: 'ETB'
    },
    {
      country_id: 'EU',
      country_name: 'European Union',
      currency: 'EUR'
    },
    {
      country_id: 'FI',
      country_name: 'Finland',
      currency: 'EUR'
    },
    {
      country_id: 'FJ',
      country_name: 'Fiji',
      currency: 'FJD'
    },
    {
      country_id: 'FK',
      country_name: 'Falkland Islands',
      currency: 'FKP'
    },
    {
      country_id: 'FM',
      country_name: 'Micronesia, Federated States Of',
      currency: 'USD'
    },
    {
      country_id: 'FO',
      country_name: 'Faroe Islands',
      currency: 'DKK'
    },
    {
      country_id: 'FR',
      country_name: 'France',
      currency: 'EUR'
    },
    {
      country_id: 'FX',
      country_name: 'France, Metropolitan',
      currency: 'EUR'
    },
    {
      country_id: 'GA',
      country_name: 'Gabon',
      currency: 'XAF'
    },
    {
      country_id: 'GB',
      country_name: 'United Kingdom',
      currency: 'GBP'
    },
    {
      country_id: 'GD',
      country_name: 'Grenada',
      currency: 'XCD'
    },
    {
      country_id: 'GE',
      country_name: 'Georgia',
      currency: 'GEL'
    },
    {
      country_id: 'GF',
      country_name: 'French Guiana',
      currency: 'EUR'
    },
    {
      country_id: 'GG',
      country_name: 'Guernsey',
      currency: 'GBP'
    },
    {
      country_id: 'GH',
      country_name: 'Ghana',
      currency: 'GHS'
    },
    {
      country_id: 'GI',
      country_name: 'Gibraltar',
      currency: 'GIP'
    },
    {
      country_id: 'GL',
      country_name: 'Greenland',
      currency: 'DKK'
    },
    {
      country_id: 'GM',
      country_name: 'Gambia',
      currency: 'GMD'
    },
    {
      country_id: 'GN',
      country_name: 'Guinea',
      currency: 'GNF'
    },
    {
      country_id: 'GP',
      country_name: 'Guadeloupe',
      currency: 'EUR'
    },
    {
      country_id: 'GQ',
      country_name: 'Equatorial Guinea',
      currency: 'XAF'
    },
    {
      country_id: 'GR',
      country_name: 'Greece',
      currency: 'EUR'
    },
    {
      country_id: 'GS',
      country_name: 'South Georgia And The South Sandwich Islands',
      currency: 'GBP'
    },
    {
      country_id: 'GT',
      country_name: 'Guatemala',
      currency: 'GTQ'
    },
    {
      country_id: 'GU',
      country_name: 'Guam',
      currency: 'USD'
    },
    {
      country_id: 'GW',
      country_name: 'Guinea-bissau',
      currency: 'XOF'
    },
    {
      country_id: 'GY',
      country_name: 'Guyana',
      currency: 'GYD'
    },
    {
      country_id: 'HK',
      country_name: 'Hong Kong',
      currency: 'HKD'
    },
    {
      country_id: 'HM',
      country_name: 'Heard Island And McDonald Islands',
      currency: 'AUD'
    },
    {
      country_id: 'HN',
      country_name: 'Honduras',
      currency: 'HNL'
    },
    {
      country_id: 'HR',
      country_name: 'Croatia',
      currency: 'HRK'
    },
    {
      country_id: 'HT',
      country_name: 'Haiti',
      currency: 'HTG'
    },
    {
      country_id: 'HU',
      country_name: 'Hungary',
      currency: 'HUF'
    },
    {
      country_id: 'IC',
      country_name: 'Canary Islands',
      currency: 'EUR'
    },
    {
      country_id: 'ID',
      country_name: 'Indonesia',
      currency: 'IDR'
    },
    {
      country_id: 'IE',
      country_name: 'Ireland',
      currency: 'EUR'
    },
    {
      country_id: 'IL',
      country_name: 'Israel',
      currency: 'ILS'
    },
    {
      country_id: 'IM',
      country_name: 'Isle Of Man',
      currency: 'GBP'
    },
    {
      country_id: 'IN',
      country_name: 'India',
      currency: 'INR'
    },
    {
      country_id: 'IO',
      country_name: 'British Indian Ocean Territory',
      currency: 'USD'
    },
    {
      country_id: 'IQ',
      country_name: 'Iraq',
      currency: 'IQD'
    },
    {
      country_id: 'IR',
      country_name: 'Iran, Islamic Republic Of',
      currency: 'IRR'
    },
    {
      country_id: 'IS',
      country_name: 'Iceland',
      currency: 'ISK'
    },
    {
      country_id: 'IT',
      country_name: 'Italy',
      currency: 'EUR'
    },
    {
      country_id: 'JE',
      country_name: 'Jersey',
      currency: 'GBP'
    },
    {
      country_id: 'JM',
      country_name: 'Jamaica',
      currency: 'JMD'
    },
    {
      country_id: 'JO',
      country_name: 'Jordan',
      currency: 'JOD'
    },
    {
      country_id: 'JP',
      country_name: 'Japan',
      currency: 'JPY'
    },
    {
      country_id: 'KE',
      country_name: 'Kenya',
      currency: 'KES'
    },
    {
      country_id: 'KG',
      country_name: 'Kyrgyzstan',
      currency: 'KGS'
    },
    {
      country_id: 'KH',
      country_name: 'Cambodia',
      currency: 'KHR'
    },
    {
      country_id: 'KI',
      country_name: 'Kiribati',
      currency: 'AUD'
    },
    {
      country_id: 'KM',
      country_name: 'Comoros',
      currency: 'KMF'
    },
    {
      country_id: 'KN',
      country_name: 'Saint Kitts And Nevis',
      currency: 'XCD'
    },
    {
      country_id: 'KP',
      country_name: 'Korea, Democratic People s Republic Of',
      currency: 'KPW'
    },
    {
      country_id: 'KR',
      country_name: 'Korea, Republic Of',
      currency: 'KRW'
    },
    {
      country_id: 'KW',
      country_name: 'Kuwait',
      currency: 'KWD'
    },
    {
      country_id: 'KY',
      country_name: 'Cayman Islands',
      currency: 'KYD'
    },
    {
      country_id: 'KZ',
      country_name: 'Kazakhstan',
      currency: 'KZT'
    },
    {
      country_id: 'LA',
      country_name: 'Lao People s Democratic Republic',
      currency: 'LAK'
    },
    {
      country_id: 'LB',
      country_name: 'Lebanon',
      currency: 'LBP'
    },
    {
      country_id: 'LC',
      country_name: 'Saint Lucia',
      currency: 'XCD'
    },
    {
      country_id: 'LI',
      country_name: 'Liechtenstein',
      currency: 'CHF'
    },
    {
      country_id: 'LK',
      country_name: 'Sri Lanka',
      currency: 'LKR'
    },
    {
      country_id: 'LR',
      country_name: 'Liberia',
      currency: 'LRD'
    },
    {
      country_id: 'LS',
      country_name: 'Lesotho',
      currency: 'LSL'
    },
    {
      country_id: 'LT',
      country_name: 'Lithuania',
      currency: 'EUR'
    },
    {
      country_id: 'LU',
      country_name: 'Luxembourg',
      currency: 'EUR'
    },
    {
      country_id: 'LV',
      country_name: 'Latvia',
      currency: 'EUR'
    },
    {
      country_id: 'LY',
      country_name: 'Libya',
      currency: 'LYD'
    },
    {
      country_id: 'MA',
      country_name: 'Morocco',
      currency: 'MAD'
    },
    {
      country_id: 'MC',
      country_name: 'Monaco',
      currency: 'EUR'
    },
    {
      country_id: 'MD',
      country_name: 'Moldova',
      currency: 'MDL'
    },
    {
      country_id: 'ME',
      country_name: 'Montenegro',
      currency: 'EUR'
    },
    {
      country_id: 'MF',
      country_name: 'Saint Martin',
      currency: 'EUR'
    },
    {
      country_id: 'MG',
      country_name: 'Madagascar',
      currency: 'MGA'
    },
    {
      country_id: 'MH',
      country_name: 'Marshall Islands',
      currency: 'USD'
    },
    {
      country_id: 'MK',
      country_name: 'Macedonia, The Former Yugoslav Republic Of',
      currency: 'MKD'
    },
    {
      country_id: 'ML',
      country_name: 'Mali',
      currency: 'XOF'
    },
    {
      country_id: 'MM',
      country_name: 'Myanmar',
      currency: 'MMK'
    },
    {
      country_id: 'MN',
      country_name: 'Mongolia',
      currency: 'MNT'
    },
    {
      country_id: 'MO',
      country_name: 'Macao',
      currency: 'MOP'
    },
    {
      country_id: 'MP',
      country_name: 'Northern Mariana Islands',
      currency: 'USD'
    },
    {
      country_id: 'MQ',
      country_name: 'Martinique',
      currency: 'EUR'
    },
    {
      country_id: 'MR',
      country_name: 'Mauritania',
      currency: 'MRO'
    },
    {
      country_id: 'MS',
      country_name: 'Montserrat',
      currency: 'XCD'
    },
    {
      country_id: 'MT',
      country_name: 'Malta',
      currency: 'EUR'
    },
    {
      country_id: 'MU',
      country_name: 'Mauritius',
      currency: 'MUR'
    },
    {
      country_id: 'MV',
      country_name: 'Maldives',
      currency: 'MVR'
    },
    {
      country_id: 'MW',
      country_name: 'Malawi',
      currency: 'MWK'
    },
    {
      country_id: 'MX',
      country_name: 'Mexico',
      currency: 'MXN'
    },
    {
      country_id: 'MY',
      country_name: 'Malaysia',
      currency: 'MYR'
    },
    {
      country_id: 'MZ',
      country_name: 'Mozambique',
      currency: 'MZN'
    },
    {
      country_id: 'NA',
      country_name: 'Namibia',
      currency: 'NAD'
    },
    {
      country_id: 'NC',
      country_name: 'New Caledonia',
      currency: 'XPF'
    },
    {
      country_id: 'NE',
      country_name: 'Niger',
      currency: 'XOF'
    },
    {
      country_id: 'NF',
      country_name: 'Norfolk Island',
      currency: 'AUD'
    },
    {
      country_id: 'NG',
      country_name: 'Nigeria',
      currency: 'NGN'
    },
    {
      country_id: 'NI',
      country_name: 'Nicaragua',
      currency: 'NIO'
    },
    {
      country_id: 'NL',
      country_name: 'Netherlands',
      currency: 'EUR'
    },
    {
      country_id: 'NO',
      country_name: 'Norway',
      currency: 'NOK'
    },
    {
      country_id: 'NP',
      country_name: 'Nepal',
      currency: 'NPR'
    },
    {
      country_id: 'NR',
      country_name: 'Nauru',
      currency: 'AUD'
    },
    {
      country_id: 'NU',
      country_name: 'Niue',
      currency: 'NZD'
    },
    {
      country_id: 'NZ',
      country_name: 'New Zealand',
      currency: 'NZD'
    },
    {
      country_id: 'OM',
      country_name: 'Oman',
      currency: 'OMR'
    },
    {
      country_id: 'PA',
      country_name: 'Panama',
      currency: 'PAB'
    },
    {
      country_id: 'PE',
      country_name: 'Peru',
      currency: 'PEN'
    },
    {
      country_id: 'PF',
      country_name: 'French Polynesia',
      currency: 'XPF'
    },
    {
      country_id: 'PG',
      country_name: 'Papua New Guinea',
      currency: 'PGK'
    },
    {
      country_id: 'PH',
      country_name: 'Philippines',
      currency: 'PHP'
    },
    {
      country_id: 'PK',
      country_name: 'Pakistan',
      currency: 'PKR'
    },
    {
      country_id: 'PL',
      country_name: 'Poland',
      currency: 'PLN'
    },
    {
      country_id: 'PM',
      country_name: 'Saint Pierre And Miquelon',
      currency: 'EUR'
    },
    {
      country_id: 'PN',
      country_name: 'Pitcairn',
      currency: 'NZD'
    },
    {
      country_id: 'PR',
      country_name: 'Puerto Rico',
      currency: 'USD'
    },
    {
      country_id: 'PS',
      country_name: 'Palestinian Territory, Occupied',
      currency: 'JOD'
    },
    {
      country_id: 'PT',
      country_name: 'Portugal',
      currency: 'EUR'
    },
    {
      country_id: 'PW',
      country_name: 'Palau',
      currency: 'USD'
    },
    {
      country_id: 'PY',
      country_name: 'Paraguay',
      currency: 'PYG'
    },
    {
      country_id: 'QA',
      country_name: 'Qatar',
      currency: 'QAR'
    },
    {
      country_id: 'RE',
      country_name: 'Reunion',
      currency: 'EUR'
    },
    {
      country_id: 'RO',
      country_name: 'Romania',
      currency: 'RON'
    },
    {
      country_id: 'RS',
      country_name: 'Serbia',
      currency: 'RSD'
    },
    {
      country_id: 'RU',
      country_name: 'Russian Federation',
      currency: 'RUB'
    },
    {
      country_id: 'RW',
      country_name: 'Rwanda',
      currency: 'RWF'
    },
    {
      country_id: 'SA',
      country_name: 'Saudi Arabia',
      currency: 'SAR'
    },
    {
      country_id: 'SB',
      country_name: 'Solomon Islands',
      currency: 'SBD'
    },
    {
      country_id: 'SC',
      country_name: 'Seychelles',
      currency: 'SCR'
    },
    {
      country_id: 'SD',
      country_name: 'Sudan',
      currency: 'SDG'
    },
    {
      country_id: 'SE',
      country_name: 'Sweden',
      currency: 'SEK'
    },
    {
      country_id: 'SG',
      country_name: 'Singapore',
      currency: 'SGD'
    },
    {
      country_id: 'SH',
      country_name: 'Saint Helena, Ascension And Tristan Da Cunha',
      currency: 'SHP'
    },
    {
      country_id: 'SI',
      country_name: 'Slovenia',
      currency: 'EUR'
    },
    {
      country_id: 'SJ',
      country_name: 'Svalbard And Jan Mayen',
      currency: 'NOK'
    },
    {
      country_id: 'SK',
      country_name: 'Slovakia',
      currency: 'EUR'
    },
    {
      country_id: 'SL',
      country_name: 'Sierra Leone',
      currency: 'SLL'
    },
    {
      country_id: 'SM',
      country_name: 'San Marino',
      currency: 'EUR'
    },
    {
      country_id: 'SN',
      country_name: 'Senegal',
      currency: 'XOF'
    },
    {
      country_id: 'SO',
      country_name: 'Somalia',
      currency: 'SOS'
    },
    {
      country_id: 'SR',
      country_name: 'Suriname',
      currency: 'SRD'
    },
    {
      country_id: 'SS',
      country_name: 'South Sudan',
      currency: 'SSP'
    },
    {
      country_id: 'ST',
      country_name: 'São Tomé and Príncipe',
      currency: 'STD'
    },
    {
      country_id: 'SU',
      country_name: 'USSR',
      currency: 'RUB'
    },
    {
      country_id: 'SV',
      country_name: 'El Salvador',
      currency: 'USD'
    },
    {
      country_id: 'SX',
      country_name: 'Sint Maarten',
      currency: 'ANG'
    },
    {
      country_id: 'SY',
      country_name: 'Syrian Arab Republic',
      currency: 'SYP'
    },
    {
      country_id: 'SZ',
      country_name: 'Swaziland',
      currency: 'SZL'
    },
    {
      country_id: 'TC',
      country_name: 'Turks And Caicos Islands',
      currency: 'USD'
    },
    {
      country_id: 'TA',
      country_name: 'Tristan de Cunha',
      currency: 'GBP'
    },
    {
      country_id: 'TD',
      country_name: 'Chad',
      currency: 'XAF'
    },
    {
      country_id: 'TF',
      country_name: 'French Southern Territories',
      currency: 'EUR'
    },
    {
      country_id: 'TG',
      country_name: 'Togo',
      currency: 'XOF'
    },
    {
      country_id: 'TH',
      country_name: 'Thailand',
      currency: 'THB'
    },
    {
      country_id: 'TJ',
      country_name: 'Tajikistan',
      currency: 'TJS'
    },
    {
      country_id: 'TK',
      country_name: 'Tokelau',
      currency: 'NZD'
    },
    {
      country_id: 'TL',
      country_name: 'East Timor',
      currency: 'USD'
    },
    {
      country_id: 'TM',
      country_name: 'Turkmenistan',
      currency: 'TMT'
    },
    {
      country_id: 'TN',
      country_name: 'Tunisia',
      currency: 'TND'
    },
    {
      country_id: 'TO',
      country_name: 'Tonga',
      currency: 'TOP'
    },
    {
      country_id: 'TR',
      country_name: 'Turkey',
      currency: 'TRY'
    },
    {
      country_id: 'TT',
      country_name: 'Trinidad And Tobago',
      currency: 'TTD'
    },
    {
      country_id: 'TV',
      country_name: 'Tuvalu',
      currency: 'AUD'
    },
    {
      country_id: 'TW',
      country_name: 'Taiwan, Province Of China',
      currency: 'TWD'
    },
    {
      country_id: 'TZ',
      country_name: 'Tanzania, United Republic Of',
      currency: 'TZS'
    },
    {
      country_id: 'UA',
      country_name: 'Ukraine',
      currency: 'UAH'
    },
    {
      country_id: 'UG',
      country_name: 'Uganda',
      currency: 'UGX'
    },
    {
      country_id: 'UM',
      country_name: 'United States Minor Outlying Islands',
      currency: 'USD'
    },
    {
      country_id: 'UK',
      country_name: 'United Kingdom',
      currency: 'GBP'
    },
    {
      country_id: 'US',
      country_name: 'United States',
      currency: 'USD'
    },
    {
      country_id: 'UY',
      country_name: 'Uruguay',
      currency: 'UYU'
    },
    {
      country_id: 'UZ',
      country_name: 'Uzbekistan',
      currency: 'UZS'
    },
    {
      country_id: 'VA',
      country_name: 'Vatican City State',
      currency: 'EUR'
    },
    {
      country_id: 'VC',
      country_name: 'Saint Vincent And The Grenadines',
      currency: 'XCD'
    },
    {
      country_id: 'VE',
      country_name: 'Venezuela, Bolivarian Republic Of',
      currency: 'VEF'
    },
    {
      country_id: 'VG',
      country_name: 'Virgin Islands (British)',
      currency: 'USD'
    },
    {
      country_id: 'VI',
      country_name: 'Virgin Islands (US)',
      currency: 'USD'
    },
    {
      country_id: 'VN',
      country_name: 'Viet Nam',
      currency: 'VND'
    },
    {
      country_id: 'VU',
      country_name: 'Vanuatu',
      currency: 'VUV'
    },
    {
      country_id: 'WF',
      country_name: 'Wallis And Futuna',
      currency: 'XPF'
    },
    {
      country_id: 'WS',
      country_name: 'Samoa',
      currency: 'WST'
    },
    {
      country_id: 'YE',
      country_name: 'Yemen',
      currency: 'YER'
    },
    {
      country_id: 'YT',
      country_name: 'Mayotte',
      currency: 'EUR'
    },
    {
      country_id: 'ZA',
      country_name: 'South Africa',
      currency: 'ZAR'
    },
    {
      country_id: 'ZM',
      country_name: 'Zambia',
      currency: 'ZMW'
    },
    {
      country_id: 'ZW',
      country_name: 'Zimbabwe',
      currency: 'USD'
    }
  ]
};
