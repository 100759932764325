import axios from 'axios';
import { config } from 'src/config.env';
import { getAccessToken } from 'src/utils/storageutils';

const restInstance = axios.create({
  baseURL: config.apiServer.url,
  headers: {
    'Content-type': 'application/json; charset=UTF-8',
    accept: 'application/json,'
  }
});

const exceptLogoutPaths = ['/auth/signin'];

export const useApi = (withAlert = true) => {
  restInstance.interceptors.request.use((config) => {
    const token = getAccessToken();
    config.headers['Authorization'] = `Bearer ${token}`;
    return config;
  });

  restInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const url = error.request.responseURL as string;
      const isExcluded =
        exceptLogoutPaths.findIndex((path) => url.endsWith(path)) > -1;
      if (error.response?.status === 401 && !isExcluded) {
        window.location.href = '/logout';
      }
      return Promise.reject(error);
    }
  );
  return restInstance;
};
