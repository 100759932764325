import ReactDOM, { render } from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { oc } from 'ts-optchain';

// import 'nprogress/nprogress.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { RecoilRoot } from 'recoil';
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from 'react-query';

declare global {
  interface Window {
    webkit?: any;
  }
}

const queryClient = new QueryClient();

window.onload = async () => {
  // try {
  //   const { result } = await getNativeInfo();
  //   // console.log('getNativeInfo:', result);
  //   const nativeLang = oc(result).language();
  //   // console.log('nativeLang:', nativeLang);
  //   const countryCode = getCountryCodeFromLocale(nativeLang);
  //   i18nInitCurrency(countryCode);
  //   await i18nInit(nativeLang as string);
  loadApp();
  // } catch (e) {
  //   console.log('i18n error:', e);
  // }
  // "@emotion/react": "11.9.0",
  // "@emotion/styled": "11.8.1",
};

function loadApp() {
  ReactDOM.render(
    <RecoilRoot>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        autoHideDuration={1500}
      >
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              {/* <ReactQueryDevtools initialIsOpen={true} /> */}
              <App />
            </BrowserRouter>
          </QueryClientProvider>
        </HelmetProvider>
      </SnackbarProvider>
    </RecoilRoot>,
    document.getElementById('root')
  );
}

serviceWorker.unregister();
