import { useRoutes } from 'react-router-dom';
import router from './router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CircularProgress, CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import RefreshToken from './pages/RefreshToken';
import { useEffect, useState } from 'react';
import { getNativeInfo } from './i18n/BridgeApi';
import { i18nInit, i18nInitCurrency } from './i18n/i18nutils';
import { oc } from 'ts-optchain';
import { useRecoilState } from 'recoil';
import { LastLocaleState } from './states/my_info';
import { config } from './config.env';
import { LoadScript } from '@react-google-maps/api';
import PageLoading from './components/Loading/PageLoading';

function App() {
  const [lastLocale, setLastLocale] = useRecoilState(LastLocaleState);
  const loadI18n = async () => {
    try {
      let nativeLang = lastLocale?.language_code;
      if (!nativeLang) {
        const { result } = await getNativeInfo();
        nativeLang = oc(result).language();
        setLastLocale({ language_code: nativeLang });
      }
      await i18nInit(nativeLang as string);
      i18nInitCurrency(nativeLang);
    } catch (e) {
      console.log('i18n error:', e);
    }
  };

  useEffect(() => {
    loadI18n();
  }, [lastLocale]);

  const content = useRoutes(router);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <RefreshToken />
        <CssBaseline />
        <LoadScript
          id="script-loader"
          googleMapsApiKey={config.keys.google.maps_key}
          libraries={['places']}
          loadingElement={<PageLoading />}
        >
          {content}
        </LoadScript>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
