import { CircularProgress } from '@mui/material';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Inner = styled.div`
  text-align: center;
`;

const Logo = styled.img`
  height: 20px;
`;

export default function PageLoading() {
  return (
    <Wrapper>
      <Inner>
        <Logo src="https://pluswhere.com/images/logo/logo_en.svg" />
      </Inner>
    </Wrapper>
  );
}
